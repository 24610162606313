import { headerJson, sectionsJson, graphics } from '../content/content';
import { formatSectionNumber } from './navbar';

export const handleSections = () => {
  const sectionsContainer = document.querySelector('.container--sections');
  let sectionNumber = 0;
  sectionsJson.forEach(({ id, title, text }) => {
    sectionsContainer.innerHTML += `
      <section
        id="${id}"
        class="section section--${id} ${title ? 'section--numbered' : ''}"
      >
        <div class="section__content">
          ${title
            ? `
              <p class="section__number">${formatSectionNumber(sectionNumber)}</span>
              <h2 class="section__title">${title}</h2>
            `
            : ''
          }
          ${
            typeof text === 'string'
            ? `<p class="section__text">${text}</p>`
            : text.map(({ subtitle, subtext, image }, idx) => `
              <section class="subsection">
                <h3 class="subsection__title">${subtitle}</h3>
                <div class="subsection__body ${idx % 2 !== 0 ? 'subsection__body--reversed' : ''}">
                  <p class="subsection__text">${subtext}</p>
                  <img class="subsection__img" src="${graphics[idx]}" alt="${image}">
                </div>
              </section>
            `).join('')
          }
        </div>
      </section>
    `;
    sectionNumber = title ? sectionNumber + 1 : sectionNumber;
  });
}

export const handleHeader = () => {
  const headerContainer = document.querySelector('.container--header');
  const { title, text, button } = headerJson;
  headerContainer.innerHTML = `
    <h1 class="header__title">${title}</h1>
    <p class="header__text">${text}</p>
    <a class="button button--primary button--sign" href="#intro">${button}</a>
  `;
}