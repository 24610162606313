import { getDOM, getActiveModuleId, getAbsoluteOffset } from "./navbar";
import icons from 'url:../assets/icons.svg';

export const handleNavigator = () => {
  const DOMElements = getDOM();
  createNavigator(DOMElements);
  handleNavigatorOnClick(DOMElements);
  handleNavigatorOnScroll(DOMElements);
}

const createNavigator = (DOMElements) => {
  const { header } = DOMElements;
  const direction = ['up', 'down'];
  const navigator = document.createElement('div');
  navigator.className = 'navigator';
  navigator.innerHTML = direction.map(dir => `
    <button class="navigator__button navigator__button--${dir}">
      <svg class="navigator__svg navigator__svg--${dir}" viewBox="0 0 101 39">
        <use href="${icons}#chevron"></use>
      </svg>
    </button>
  `).join('');
  header.appendChild(navigator);
}

const handleNavigatorButtonsVisibility = (index, maxIndex, button, buttonType) => {
  const visibleClass = 'navigator__button--visible';
  if ((index === 0 && buttonType === 'up')
    || (index >= maxIndex && buttonType === 'down')
  ) {
    button.classList.remove(visibleClass);
  } else {
    button.classList.add(visibleClass);
  }
}

const handleNavigatorOnScroll = (DOMElements) => {
  const { modulesIds } = DOMElements;
  let currentModuleId = undefined;
  const maxIndex = modulesIds.length - 1;
  const buttons = [
    document.querySelector('.navigator .navigator__button--up'),
    document.querySelector('.navigator .navigator__button--down')
  ];
  const setVisibility = () => {
    const activeModuleId = getActiveModuleId(DOMElements);
    buttons.forEach((button, idx) => {
      const buttonType = idx === 0 ? 'up' : 'down';
      if (activeModuleId !== currentModuleId) {
        const activeModuleIdx = modulesIds.indexOf(activeModuleId);
        handleNavigatorButtonsVisibility(activeModuleIdx, maxIndex, button, buttonType);
      }
    });
    currentModuleId = activeModuleId;
  }
  setVisibility();
  window.addEventListener('scroll', setVisibility);
}

const handleNavigatorOnClick = (DOMElements) => {
  const { modulesIds } = DOMElements;
  const buttons = [
    document.querySelector('.navigator .navigator__button--up'),
    document.querySelector('.navigator .navigator__button--down')
  ];

  buttons.forEach((button, idx) => {
    button.addEventListener('click', function() {
      const activeModuleId = getActiveModuleId(DOMElements);
      const activeModuleIdx = modulesIds.indexOf(activeModuleId);

      if ((activeModuleIdx > 0 && idx === 0)
        || (activeModuleIdx < modulesIds.length - 1 && idx === 1)
      ) {
        const nextModuleIdx = idx === 0 ? activeModuleIdx - 1 : activeModuleIdx + 1;
        const nextModuleId = modulesIds[nextModuleIdx];
        const nextModule = document.getElementById(nextModuleId);
        window.scrollTo(0, getAbsoluteOffset(nextModule).top);
      }
    });
  });
}