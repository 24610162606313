import { handleHexGrid } from './hexGrid'; 
import { handleHeader, handleSections } from './fillContent';
import { handleNavbar } from './navbar';
import { handleNavigator } from './navigator';
import { handleModals } from './modal';
import { handleForm } from './form';
import { handleOverlay } from './overlay';

window.onload = function() {
  handleHeader();
  handleSections();
  handleHexGrid();
  handleNavbar();
  handleNavigator();
  handleModals();
  handleForm();
  handleOverlay();
}
