const signaturesData = {};

const addFooterInfo = ({ signatures }) => {
  const footerText = document.querySelector('.form__text');
  footerText.innerHTML = `
    Join the
    <span class="form__text--number">${signatures.length}</span>
    people that have already signed the manifesto
  `;
}

const updateSignaturesButton = ({ signatures }) => {
  const signaturesButton = document.querySelector('.footer .button--signatures');
  signaturesButton.innerHTML = `
    Show signatures <span = class="label label--signatures">${signatures.length}</span>
  `;
}

const handleRodoCheckbox = () => {
  const rodoCheckbox = document.querySelector('input#rodo');
  const rodoCheckmark = document.getElementById('rodo_checkmark');
  const signButton = document.querySelector('footer .button--sign');

  rodoCheckbox.addEventListener('click', function() {
    const action = this.checked ? 'remove' : 'add';
    signButton.classList[action]('button--disabled');
    signButton.disabled = !this.checked;
  });

  rodoCheckmark.addEventListener('click', function() {
    rodoCheckbox.click();
  });
}

export const handleForm = (json) => {
  if (!json) return false;
  Object.assign(signaturesData, json);
  addFooterInfo(signaturesData);
  updateSignaturesButton(signaturesData);
  handleRodoCheckbox();
}
