export const getDOM = () => {
  const header = document.getElementById('header');
  const footer = document.getElementById('footer');
  const sections = document.querySelectorAll('.container--sections section.section');
  const numberedSections = document.querySelectorAll('.container--sections section.section--numbered');
  const numberedSectionsIds = [...numberedSections].map(section => section.id);
  const modules = [header, ...sections, footer];
  const modulesIds = [...modules].map(module => module.id);
  return {
    header,
    numberedSectionsIds,
    modules,
    modulesIds
  }
}

export const handleNavbar = () => {
  const DOMElements = getDOM();
  createNavbar(DOMElements);
  handleNavbarButtonsOnClick();
  handleElementsOnScroll(DOMElements);
}

export const formatSectionNumber = (idx) => {
  const number = idx + 1;
  return number <= 9 ? `0${number}` : number;
}

export const getAbsoluteOffset = (elem) => {
  const rect = elem.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}

const createNavbar = (DOMElements) => {
  const { header, numberedSectionsIds } = DOMElements;
  const navbar = document.createElement('nav');
  const navbarList = document.createElement('ul')
  const navbarSign = document.createElement('div');

  navbarSign.innerHTML = `
    <div class="navbar__cta">
      <a href="#footer" class="navbar__button button button--primary button--sign">
        Sign Manifesto  
      </a>
    </div>
  `;
  navbar.className = 'navbar';
  navbarList.className = 'navbar__list';
  navbar.appendChild(navbarList);
  header.appendChild(navbar);

  numberedSectionsIds.map((id, idx) => {
    navbarList.innerHTML += `
      <li class="navbar__item">
        <button class="navbar__button navbar__button--section" data-id="${id}">
          <span class="navbar__number">${formatSectionNumber(idx)}</span>${id}
        </button>
      </li>
    `;
  });
  navbarList.appendChild(navbarSign);
}

const handleNavbarButtonsOnClick = () => {
  const navbarButtons = document.querySelectorAll('nav.navbar .navbar__button--section');
  [...navbarButtons].forEach(button => {
    button.addEventListener('click', function() {
      const section = document.querySelector(`.section--${this.getAttribute('data-id')}`);
      window.scrollTo(0, getAbsoluteOffset(section).top);
    });
  });
}

export const getActiveModuleId = (DOMElements) => {
  const { modules, modulesIds } = DOMElements;
  const viewHeight = window.innerHeight;

  const activeModuleIndex = [...modules].map(module => {
    const content = module.firstElementChild;
    const { top, bottom } = content.getBoundingClientRect();
    const visibleFromTop = Math.max(Math.max(top, 0), Math.min(top, viewHeight));
    const visibleFromBottom = Math.min(Math.max(bottom, 0), Math.min(bottom, viewHeight));
    return Math.max(visibleFromBottom - visibleFromTop, 0);
  }).reduce((iMax, x, i, arr) =>
    arr.every(elem => elem === 0) ? -1 : x > arr[iMax] ? i : iMax, 0
  );
  return modulesIds[activeModuleIndex];
}

const handleNavbarButtonsVisibility = (target) => {
  const navbar = document.querySelector('nav.navbar');
  const navbarButtons = navbar.querySelectorAll('.navbar__button--section');
  [...navbarButtons].forEach(button => {
    button.classList.remove('navbar__button--active');
    if (button === target) {
      button.classList.add('navbar__button--active');
    }
  });
}

const handleSectionsVisibility = (currSection, prevSection) => {
  const prevSectionContent = prevSection ? prevSection.querySelector('.section__content') : null;
  const currSectionContent = currSection ? currSection.querySelector('.section__content') : null;
  prevSectionContent ? prevSectionContent.classList.remove('section__content--active') : false;
  currSectionContent ? currSectionContent.classList.add('section__content--active') : false;
}

const handleNavbarSignVisibility = (currSection) => {
  const navbarSign = document.querySelector('.navbar__cta');
  if (currSection === 'header' || currSection === 'footer') {
    navbarSign.classList.remove('navbar__cta--visible');
  } else {
    navbarSign.classList.add('navbar__cta--visible');
  }  
}

const handleElementsOnScroll = (DOMElements) => {
  let currentModuleId = undefined;
  const activeModuleId = getActiveModuleId(DOMElements);
  handleNavbarSignVisibility(activeModuleId);

  window.addEventListener('scroll', function() {
    const activeModuleId = getActiveModuleId(DOMElements);
    if (activeModuleId !== currentModuleId) {
      const activeSection = document.querySelector(`.section#${activeModuleId}`);
      const previousSection = document.querySelector(`.section#${currentModuleId}`);
      const activeButton = document.querySelector(`.navbar__button[data-id=${activeModuleId}]`);
      handleNavbarButtonsVisibility(activeButton);
      handleSectionsVisibility(activeSection, previousSection);
      handleNavbarSignVisibility(activeModuleId);
      currentModuleId = activeModuleId;
    }
  });
}
