import { hexDesktop, hexTablet, hexMobile } from './hexLayouts';

// calculate hex cell width as css calc() function
const getCellWidth = (total) => `calc(${1 / (total) * 100}% + 2px)`;

const getRowHeight = (rowWidth, cellsInRow) => {
  const cellWidth = rowWidth / cellsInRow;
  const rowHeight = cellWidth * 2/ Math.sqrt(3);
  return `${rowHeight}px`;
}

const getMedia = (media) => {
  return media.reduce((prev, curr) => 
  window.innerWidth >= curr.breakpoint ? curr : prev, media[0]);
}

const getSvg = (className, width, height) => {
  return `
    <svg class=${className} viewBox="0 0 ${width} ${height}">
      <path d="M100 28.867v57.735L50 115.47 0 86.602V28.867L50 0z"/>
    </svg>
  `
}

const getTotalColumns = (data) => {
  return data.reduce((max, curr) => curr.length > max.length ? curr : max).length;
}

// get one hexagonal cell
const getCell = (hexLevel) => {
  const hexNode = document.createElement('div');
  hexNode.className = 'hexGrid__cell';
  const hexClass = `"cell ${[1,2,3,4].includes(hexLevel) ? 'cell--detached' : 'cell--attached'} ${hexLevel ? `cell--${hexLevel}` : ''}"`;
  hexNode.innerHTML = getSvg(hexClass, 100, 115.47);
  return hexNode;
}

// generate hexagonal grid
const generateHexGrid = (container, data) => {
  const totalInBigRow = getTotalColumns(data);
  const totalInSmallRow = totalInBigRow - 1;
  const newRowMargin = `${-1 / (totalInSmallRow  * 2) / Math.sqrt(3) * 100 - 0.2}%`;
  const newBigRowWidth = `${(totalInBigRow) / totalInSmallRow * 100}%`;
  const newBigRowLeftMargin = `${-1 / (totalInSmallRow * 2) * 100}%`;
  const lastRow = data.length;
  const isEvenRowBigger = data.findIndex(row => row.length === totalInBigRow);
  const rowHeight = getRowHeight(container.clientWidth, totalInSmallRow);

  // clean container's node structure
  container.innerHTML = '';

  data.forEach((row, index) => {
    const newRow = document.createElement('div');
    const rowNumber = index + 1;
    newRow.className = 'hexGrid__row';
    newRow.style.marginTop = newRowMargin;
    newRow.style.height = rowHeight;

    // set width and margin of bigger rows
    if ((isEvenRowBigger && rowNumber % 2 === 0) || (!isEvenRowBigger && rowNumber % 2 !== 0)) {
      newRow.style.width = newBigRowWidth;
      newRow.style.marginLeft = newBigRowLeftMargin;
    }
    // set bottom margin of the last row
    if (rowNumber === lastRow) newRow.style.marginBottom = newRowMargin;
    container.appendChild(newRow);

    // create new cell
    row.forEach(cell => {
      const lastRow = container.lastElementChild;
      const newCell = getCell(cell);
      newCell.style.width = row % 2 === 0 && isEvenRowBigger
      ? getCellWidth(totalInBigRow)
      : getCellWidth(totalInSmallRow);
      lastRow.appendChild(newCell);
    });
  });
}

export const handleHexGrid = () => {
  const media = [
    {
      breakpoint: 0,
      data: [ hexMobile.header, hexMobile.main, hexMobile.footer ]
    },
    {
      breakpoint: 768,
      data: [ hexTablet.header, hexTablet.main, hexTablet.footer ]
    },
    {
      breakpoint: 1400,
      data: [ hexDesktop.header, hexDesktop.main, hexDesktop.footer ]
    }
  ];
  const containers = [
    document.getElementById('headerHexGrid'),
    document.getElementById('mainHexGrid'),
    document.getElementById('footerHexGrid')
  ];
  const { data } = getMedia(media);
  data.forEach((d, i) => generateHexGrid(containers[i], d));
  let currentMediaBreakpoint = getMedia(media).breakpoint;

  window.addEventListener('resize', function() {
    const { breakpoint, data } = getMedia(media);
    if (breakpoint !== currentMediaBreakpoint) {
      currentMediaBreakpoint = breakpoint;
      data.forEach((d, i) => generateHexGrid(containers[i], d));
    }
  });
  
  window.addEventListener('resize', function() {
    const gridRows = document.querySelectorAll('.hexGrid__row');
    const cellsInRow = [...gridRows].reduce((a, b) => a < b.children.length
      ? a
      : b.children.length, gridRows[0].children.length);
    [...gridRows].forEach(
      row => row.style.height = getRowHeight(containers[0].clientWidth, cellsInRow)
    );
  });
}
