// Layout for desktop screens
export const hexDesktop = {
  header: [
    [ 3,2,1,1,0,0,0,0,0,0,0 ],
    [3,2,1,0,0,0,0,0,0,0,0,1],
    [ 1,1,0,0,0,0,0,0,0,0,1 ],
    [0,0,0,0,0,0,0,0,0,0,0,1],
    [ 0,0,0,0,0,0,0,0,0,0,1 ],
    [0,0,0,0,0,0,0,0,0,1,1,2],
    [ 0,0,0,0,0,1,1,1,1,2,2 ],
    [0,1,1,1,1,1,2,2,2,2,3,3],
    [ 1,2,2,2,2,2,3,3,3,3,5 ],
    [2,2,3,3,3,3,3,5,5,5,5,5],
    [ 3,3,5,5,5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5,5,5,5,5]
  ],
  main: [
    [ 5,5,5,5,5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5,5,5,5,5 ],
    [4,5,5,5,5,5,5,5,5,5,5,5],
    [ 4,5,5,5,5,5,5,5,5,5,5 ],
    [3,4,5,5,5,5,5,5,5,5,5,5],
    [ 4,5,5,5,5,5,5,5,5,5,5 ],
    [3,4,5,5,5,5,5,5,5,5,5,5],
    [ 4,5,5,5,5,5,5,5,5,5,5 ],
    [3,4,5,5,5,5,5,5,5,5,5,5],
    [ 3,4,5,5,5,5,5,5,5,5,5 ],
    [2,3,4,5,5,5,5,5,5,5,5,5],
    [ 3,4,5,5,5,5,5,5,5,5,5 ],
    [2,3,4,5,5,5,5,5,5,5,5,5],
    [ 3,4,5,5,5,5,5,5,5,5,5 ],
    [4,5,5,5,5,5,5,5,5,5,5,4],
    [ 5,5,5,5,5,5,5,5,5,5,4 ],
    [5,5,5,5,5,5,5,5,5,5,4,3],
    [ 5,5,5,5,5,5,5,5,5,5,4 ],
    [5,5,5,5,5,5,5,5,5,5,4,3],
    [ 5,5,5,5,5,5,5,5,5,4,3 ],
    [4,5,5,5,5,5,5,5,5,5,4,3],
    [ 4,5,5,5,5,5,5,5,5,5,4 ],
    [3,4,5,5,5,5,5,5,5,5,5,4],
    [ 4,5,5,5,5,5,5,5,5,5,5 ],
    [3,4,5,5,5,5,5,5,5,5,5,5],
    [ 3,4,5,5,5,5,5,5,5,5,5 ],
    [3,4,5,5,5,5,5,5,5,5,5,5],
    [ 3,4,5,5,5,5,5,5,5,5,5 ],
    [4,4,5,5,5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5,5,5,4,4],
    [ 5,5,5,5,5,5,5,5,5,4,3 ],
    [5,5,5,5,5,5,5,5,5,5,4,3],
    [ 5,5,5,5,5,5,5,5,5,4,3 ],
    [5,5,5,5,5,5,5,5,5,4,3,2],
    [ 5,5,5,5,5,5,5,5,4,3,2 ],
    [4,5,5,5,5,5,5,5,5,4,4,2],
    [ 4,5,5,5,5,5,5,5,5,5,4 ],
    [3,4,5,5,5,5,5,5,5,5,5,4],
    [ 3,4,5,5,5,5,5,5,5,5,5 ],
    [3,4,5,5,5,5,5,5,5,5,5,5],
    [ 3,4,5,5,5,5,5,5,5,5,5 ],
    [2,3,4,5,5,5,5,5,5,5,5,5],
    [ 3,4,5,5,5,5,5,5,5,5,5 ],
    [3,4,5,5,5,5,5,5,5,5,5,5],
    [ 4,5,5,5,5,5,5,5,5,5,5 ],
    [4,5,5,5,5,5,5,5,5,5,5,5]
  ],
  footer: [
    [ 5,5,5,5,5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5,5,5,5,3],
    [ 5,5,5,5,5,5,3,3,3,3,3 ],
    [5,5,5,3,3,3,3,2,2,2,2,2],
    [ 3,3,3,2,2,2,2,1,1,1,1 ],
    [3,2,2,2,1,1,1,1,0,0,0,0],
    [ 2,1,1,1,0,0,0,0,0,0,0 ],
    [1,1,0,0,0,0,0,0,0,0,0,0],
    [ 0,0,0,0,0,0,0,0,0,0,0 ]
  ]
}

// Layout for tablet screens
export const hexTablet = {
  header: [
    [ 2,1,1,0,0,0,0 ],
    [2,1,0,0,0,0,0,0],
    [ 1,0,0,0,0,0,0 ],
    [1,0,0,0,0,0,0,0],
    [ 0,0,0,0,0,0,1 ],
    [0,0,0,0,0,1,1,2],
    [ 0,0,1,1,1,2,2 ],
    [0,1,1,2,2,2,3,3],
    [ 1,2,2,3,3,3,5 ],
    [2,2,3,3,5,5,5,5],
    [ 3,3,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5]
  ],
  main: [
    [ 5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,4],
    [ 5,5,5,5,5,5,4 ],
    [5,5,5,5,5,5,5,4],
    [ 5,5,5,5,5,5,4 ],
    [5,5,5,5,5,5,4,3],
    [ 5,5,5,5,5,5,4 ],
    [5,5,5,5,5,5,5,4],
    [ 5,5,5,5,5,5,5 ],
    [4,5,5,5,5,5,5,5],
    [ 4,5,5,5,5,5,5 ],
    [3,4,5,5,5,5,5,5],
    [ 4,5,5,5,5,5,5 ],
    [3,4,5,5,5,5,5,5],
    [ 4,5,5,5,5,5,5 ],
    [4,5,5,5,5,5,5,4],
    [ 5,5,5,5,5,5,4 ],
    [5,5,5,5,5,5,5,4],
    [ 5,5,5,5,5,5,4 ],
    [5,5,5,5,5,5,5,4],
    [ 5,5,5,5,5,5,4 ],
    [4,5,5,5,5,5,5,4],
    [ 4,5,5,5,5,5,5 ],
    [3,4,5,5,5,5,5,5],
    [ 3,4,5,5,5,5,5 ],
    [3,4,5,5,5,5,5,5],
    [ 4,5,5,5,5,5,5 ],
    [4,5,5,5,5,5,5,5]
  ],
  footer: [
    [5,5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5,3],
    [ 5,5,5,5,5,3,3 ],
    [5,5,3,3,3,3,2,2],
    [ 3,3,2,2,2,2,1 ],
    [2,2,2,1,1,1,1,0],
    [ 1,1,1,0,0,0,0 ],
    [1,0,0,0,0,0,0,0],
    [ 0,0,0,0,0,0,0 ]
  ]
}

// Layout for mobile screens
export const hexMobile = {
  header: [
    [ 2,1,1,0,0,0 ],
    [2,1,1,0,0,0,0],
    [ 1,0,0,0,0,0 ],
    [1,0,0,0,0,0,0],
    [ 0,0,0,0,0,0 ],
    [0,0,0,0,0,0,1],
    [ 0,0,0,0,0,1 ],
    [0,0,0,0,0,0,1],
    [ 0,0,0,0,0,1 ],
    [0,0,0,0,0,0,1],
    [ 0,0,0,0,0,1 ],
    [0,0,0,0,0,1,2],
    [ 0,0,0,0,1,2 ],
    [0,0,1,1,1,2,3],
    [ 1,1,2,2,2,3 ],
    [1,2,2,3,3,3,5],
    [ 2,3,3,5,5,5 ],
    [3,3,5,5,5,5,5],
    [ 5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5]
  ],
  main: [
    [ 5,5,5,5,5,5 ],
    [5,5,5,5,5,5,5]
  ],
  footer: [
    [5,5,5,5,5,5,5],
    [ 5,5,5,5,5,5 ],
    [5,5,5,5,5,5,3],
    [ 5,5,5,5,5,3 ],
    [5,5,5,5,5,3,2],
    [ 5,5,5,3,3,2 ],
    [5,3,3,3,2,2,1],
    [ 3,2,2,2,1,1 ],
    [2,2,1,1,1,0,0],
    [ 1,1,0,0,0,0 ],
    [1,0,0,0,0,0,0],
    [ 0,0,0,0,0,0 ]
  ]
}
