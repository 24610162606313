import { privacyJson } from '../content/content';
import signaturesJson from 'url:../content/signatures.json';
import { handleForm } from './form';
import icons from 'url:../assets/icons.svg';

const privacyModal = document.getElementById('privacy');
const signaturesModal = document.getElementById('signatures');

export const createModal = ({ title }, modal, content) => {
  modal.innerHTML = `
    <article class="modal__wrapper">
      <header class="modal__header">
        <h3 class="modal__title">${title}</h3>
        <button class="modal__close">
          <svg class="modal__svg" viewBox="0 0 100 100">
            <use href="${icons}#close"></use>
          </svg>
        </button>
      </header>
      <div class="modal__body">${content}</div>
    </article>
  `;
}

const getPrivacyContent = (json) => {
  return json.sections.map(({ title, text }) => {
    return `
      <section class="section">
        <h4 class="section__title">${title}</h4>
        <p class="section__text">${text.replace(/(?:\r\n|\r|\n)/g, '<br>')}</p>
      </section>
    `;
  }).join('');
}

export const getSignaturesContent = ({ signatures }) => {
  return `<i>Note: signatures are updated with a delay - they don't appear immediately.</i><ul class="signatures">
    ${signatures.map(({ NAME, COMPANY }) => {
      return `
        <li class="signatures__item">
          <p class="signatures__text">
            <span>${NAME}</span>
            <span>${COMPANY}</span>
          </p>
        </li>
      `;
    }).join('')}
  </ul>`;
}

export const handleModal = (type, modal) => {
  const body = document.querySelector('body');
  const triggerButtons = document.querySelectorAll(`.button--${type}`);
  const closeButton = document.querySelector(`.modal--${type} .modal__close`);

  [...triggerButtons].forEach(button => {
    button.addEventListener('click', function(e) {
      e.preventDefault();
      modal.classList.add('modal--visible');
      body.style = 'overflow: hidden;';
    });
    closeButton.addEventListener('click', function() {
      modal.classList.remove('modal--visible');
      body.style = '';
    });
  });
}

export const handleModals = () => {
  // Privacy Policy Modal
  createModal(privacyJson, privacyModal, getPrivacyContent(privacyJson));
  handleModal('privacy', privacyModal);
  // Signatures Modal
  fetch(signaturesJson).then(res => res.json()).then((json) => {
    createModal(json, signaturesModal, getSignaturesContent(json));
    handleModal('signatures', signaturesModal);
    handleForm(json);
  }).catch(err => {
    console.log("Error Reading data " + err);
  });
}
