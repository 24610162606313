import graphic1 from 'url:../assets/graphics/graphic_1.svg';
import graphic2 from 'url:../assets/graphics/graphic_2.svg';
import graphic3 from 'url:../assets/graphics/graphic_3.svg';
export const graphics = [graphic1, graphic2, graphic3];

export const privacyJson = {
  title: "Privacy Policy",
  sections: [
    {
      title: "WHO ARE WE?",
      text: `Appsilon spółka z ograniczoną odpowiedzialnością with its registered seat in Warsaw at Chmielna 2/31, postal code 00-020, Poland, entered into the register of entrepreneurs kept by the District Court for the Capital City of Warsaw in Warsaw, XII Commercial Division of the National Court Register, under KRS number: 0000483137, NIP [tax identification number]: 5252569920, with share capital PLN 5000, further referred to as “the Company” is a data controller under Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation further referred to as “GDPR”). 
      This privacy policy (“Policy”) describes how the Company and its related companies process the personal data of users collected via this website (the “Site”) including newsletter sign-up form, contact form, signing manifesto form or via any correspondence with the Company.`
    },
    {
      title: "WHAT WE COLLECT?",
      text: `We may collect the following information:
      * name, surname, e-mail address, name of the organisation that you are affiliated with and any other data you provide voluntary when you contact us,
      * name, surname, e-mail address and name of the organisation that you are affiliated with, when you subscribe to our newsletter,
      * name, surname, e-mail address, name of the organisation that you are affiliated with, when you sign the manifesto.
      Users may also visit our Site anonymously. We use cookies to grant positive experience while using our Site, gather general visitor information and track visits to our Site. Please refer to the Cookies section below.`
    },
    {
      title: "WHY WE COLLECT DATA?",
      text: `Personal data are processed to: 
      * answer all your questions and requests sent through the electronic forms and using contact addresses published on the Site, including interactive windows (Article 6 point 1 f) GDPR),
      * investigate or safeguard against possible claims (Article 6 point 1 f) GDPR), 
      * communicate and provide commercial or marketing information via newsletter on the basis of your voluntary consent (Article 6 point 1 a) GDPR),
      * to record that you have signed the manifesto, as if you do so, the information that you submit will be listed publicly on the Site as a signatory on the basis of your voluntary consent (Article 6 point 1 a) GDPR).
      Providing the personal data is voluntary, however, failure to do so may prevent you, depending on the specific case, use of selected functionalities within the Site or contact us. If at any time you would like to unsubscribe from receiving a newsletter, or delete your signature from manifesto, you can simply email hello@appsilon.com`
    },
    {
      title: "HOW LONG WE PROCESS YOUR DATA?",
      text: `Personal data obtained through the contact forms or addresses indicated for communication with the Company will be processed for the duration of the communication or until an objection to the processing is raised, but no longer than the claims limitation period. 
      Personal data processed for the purposes of newsletters and publishing signatures of manifesto will be processed until consent is withdrawn.`
    },
    {
      title: "RECIPIENTS OF DATA",
      text: `The recipients of personal data will be other entities providing appropriate IT services related to the maintenance of the Site, hosting, mailbox administration and management, consulting, accounting, finance, newsletter and legal companies, contractors and co-workers of the Company, affiliates of the Company, trusted vendors, marketing agencies, app and social media platform providers.
      Personal data may be transferred outside the European Economic Area (EEA) to a third party country, to entities fulfilling a required protection level based on the European Commission requirements. If there is any transfer of data from EEA to other countries, data processors shall comply with the law regulations that ensure an adequate level of security to that of the EU regulations. If personal data is transferred to the third country that does not comply with EC requirements, any processing will be based on up-to-date standard contractual clauses approved by the European Commission. Information and copy of safeguards including such standard contractual clauses may be provided at the Company's registered office or via e-mail.`
    },
    {
      title: "YOUR RIGHTS",
      text: `If Company process your data, you have the right to:
      * access the data, 
      * rectify the data, 
      * restrict the processing of the data, 
      * erase the data, 
      * transfer the data, 
      * object to the processing of data which takes place on the basis of the Company’s legitimate interest,
      * withdraw your consent (where processing is based on consent) at any time without affecting the lawfulness of processing carried out on the basis of consent before its withdrawal.
      The users whose data are processed have the right to lodge a complaint with the adequate supervisory body e.g. in Poland to President of the Office for Personal Data Protection (postal address: ul. Stawki 2, 00-193 Warsaw, Poland).`
    },
    {
      title: "INFORMATION AUTOMATICALLY COLLECTED",
      text: `Company automatically logs information about you and your computer when you visit our Site. For example,we log your computer operating system type, browser type, browser language, the website you visited before browsing our Site, pages you viewed, how long you spent on a page, access times, Internet protocol (IP) address and information about your use of and actions on our Site.`
    },
    {
      title: "COOKIES",
      text: `We may log information using “cookies.” Cookies are small data text files stored on your device by a website. Cookies help us make our Site and your visit better. Cookies allow for recognition of the device and appropriate display of the Site adjusted to the individual preferences of the user. Cookies usually contain the name of the website they come from, the time of storage on the device and unique number.
      In addition, cookies are used to create anonymous, aggregate statistics that enable us to check how users use the Site, which makes it possible to improve the content and structure of the Site, excluding personal identification of a user. Cookies also make it possible to maintain a user session, in order to store preferences, consents, etc., so that the user does not have to enter the same information each time he visits the Site.
      By default, the software used to browse the Internet allows cookies to be placed on the user's device. These settings can be changed to block the automatic handling of cookies in the settings of your web browser or inform on their sending to your device each time. Detailed information on the possibility and methods of use, control and management of cookies are available in the settings of the web browser used by the user. Restricting the use of cookies may affect some of the functionality available on the Site.
      With regard to the time of cookies’ storage on the device there are:
      * temporary cookies - placed on the device for the time of use of the browser, after closing the session, are deleted,
      * permanent cookies, which are stored on the device of the user for the time specified in the parameters of cookies or until they are deleted by the user.
      Cookies do not cause any configuration changes in the user's device or in software installed on such devices.
      Here is a list of cookies used by the Site:
      1. analytical cookies for statistical analysis purposes so that we understand how visitors use and move around our Site, which improvements and adaptions should be made or ensuring that our Site works properly on the devices and technologies that our visitors use such as: 
      _ga_XT0E3NB0S4 - Analytics - This cookie is installed by Google Analytics. - 2 years
      _ga - Analytics - The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors. - 2 years
      _gid - Analytics - Installed by Google Analytics, _gid cookie stores information on how visitors use a website, while also creating an analytics report of the website's performance. Some of the data that are collected include the number of visitors, their source, and the pages they visit anonymously. - 1 day
      _gat_gtag_UA_46680230_10 - Analytics - Set by Google to distinguish users. - 1 minute`
    },
    {
      title: "THIRD PARTY SITES AND PLUGINS",
      text: `On our Site we use hyperlinks and social plugins (Linkedin, Github, Twitter) to third parties websites. When using plug-ins, your Internet browser establishes a direct connection to the servers of the respective social network. This tells the provider that your browser has displayed our Site, even if you do not have a user account with the provider or are not currently logged in there. The log files (including the IP address) are sent by your browser directly to a server of the relevant provider and stored there. The provider or its server may be located outside the EU or EEA.
      The plugins are independent extensions of the social network providers. We therefore have no influence on the extent of the data collected by the social network providers using the plugins.
      The purpose and scope of data collection, further processing and use of data by the third parties, as well as the associated rights of users and setting options for protecting privacy are described in the privacy policy information of the respective provider. Company has no control over, and is not responsible for, the privacy policies and practices of third parties.
      If you do not want the providers of social networks to receive data and possibly save or use them, you should not use the respective plugins on our Site.`
    },
    {
      title: "SECURITY OF YOUR PERSONAL INFORMATION",
      text: `We take steps to help protect personal information. All information provided to us is stored on secure servers and guarded by strict procedures and well-trained staff. The Company processes personal data with due diligence when selecting and applying appropriate technical and organizational measures. 
      Personal data collected by the Company are: 
      * processed in a lawful manner, 
      * collected for specified, lawful purposes and not subjected to further processing incompatible with these purposes, 
      * adequate and limited to what is necessary for the purposes for which they are processed,
      * corrected and, if necessary, updated, 
      * kept for no longer than necessary to achieve the purpose of processing,
      * processed in a manner that ensures appropriate security. `
    },
    {
      title: "CONTACT INFORMATION",
      text: `We welcome your comments or questions about this Privacy policy. You may contact us at our address: hello@appsilon.com.`
    },
    {
      title: "CHANGES TO THIS PRIVACY POLICY",
      text: `We reserve the right to change this Privacy policy. If we make any changes, we will change the last updated date below.
      This Privacy policy was last updated on  24.03.2022`
    }
  ]
};

export const headerJson = {
  title: "Enterprise R Shiny Manifesto",
  text: "R Shiny is a perfect fit for data-centric applications in enterprise.",
  button: "Read the Manifesto"
};

export const sectionsJson = [
  {
    id: "intro",
    title: null,
    text: [
      {
        subtitle: "As enterprise Shiny app creators, we are dedicated to making a difference in the lives of our customers and co-workers",
        subtext: "Shiny enables us to deliver value extremely quickly. We can implement users’ ideas right away. Data scientists and software engineers work together in the same codebase.",
        image: "Programmers"
      },
      {
        subtitle: "Shiny drives adoption more than alternatives",
        subtext: "Enterprise apps built in Shiny have a much higher chance of achieving widespread user adoption and an overall positive impact on the organization thanks to speed of iteration in building the apps to users’ needs.",
        image: "Data science"
      },
      {
        subtitle: "R is fully ready for production and commercial use",
        subtext: "Enterprise software requires high quality and reliability, so some people wonder if Shiny is ready for that. We have seen many companies achieve amazing success with Shiny, but only when done right. Here’s what we believe is crucial:",
        image: "Business deal"
      }
    ]
  },
  {
    id: "development",
    title: "It's software development",
    text: "We treat R & Shiny as any other programming tool. We use engineering practices established in the software industry, such as version control, peer reviews, unit tests, end to end tests, CI/CD, good documentation and proper architecture."
  },
  {
    id: "quality",
    title: "Quality matters",
    text: "Shiny allows teams to iterate very quickly. However, the faster you go the more you need to be aware of technical debt. Sacrificing quality causes projects to become unmaintainable and fail in the long term. We care about quality from the very beginning to ensure long-term success."
  },
  {
    id: "users",
    title: "Users are the key",
    text: "We focus all activities on the user, from the very beginning of the project till its end. Understanding and designing for end users is critical for success with enterprise projects. We see immense value in usage metrics and logs and analyzing users' interactions with the apps."
  },
  {
    id: "interface",
    title: "Interface is a gamechanger",
    text: "We put a lot of effort into designing the best possible User Experience. We have experienced many times that a well-designed, useful, and beautiful user interface makes the difference, especially for enterprise Shiny applications. Introducing a new app is always a challenge, excellent UI means more users, better reception, and a larger impact on the organization."
  },
  {
    id: "technology",
    title: "Shiny isn't always the answer",
    text: "We believe it's important to know when to choose another technology over Shiny. Shiny is not a silver bullet in all cases. Very complex system, very large number of users? We'd consider mainstream web technologies, or BI tools for read-only needs. However, if a project is centered around data processing, data exploration, or making decisions based on data - we know Shiny will give us a unique advantage."
  },
  {
    id: "strengths",
    title: "Leverage Shiny's strengths",
    text: "Shiny has a unique set of strengths: fast development, a short feedback loop with users, R data analysis packages, and visualization libraries. Using Shiny without leveraging those strengths misses the point. We strive to identify which Shiny's advantages are the most important for the project's business success, and exploit them to the limits."
  }
];
